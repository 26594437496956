import { GET, PATCH } from "./service";

export const Read = async () => {
  const response = await GET(`/property/dashboard?take=500&skip=0`); 
  return response;
}

export const UpdatePropertyPatrol = async (userUuid, propertyUuid, payload) => {
  const response = await PATCH(`/properties/${userUuid}/${propertyUuid}`, payload); 
  return response;
};
