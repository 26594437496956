import React, { useContext, useState } from 'react';
import { FormText, Title } from '../../../ui/styled';
import { CompanyButtonContainer } from './styled';
import Wrapper from '../Wrapper';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import { TextField } from '@mui/material';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { PATCH } from '../../../service/service';

export default function ModalReprovee() {

  const { modal, setModal, selectedProduct, handleProperties, patrols } = useContext(AuthContext)
  const [selectedPatrol, setSelectedPatrol] = useState(null);
  const [rejectionReason, setRejectionReason] = useState('');

  const close = () => {
    setModal(null)
  }

  const handleSave = () => {
    // do something
    close()
  }

  return (
    <>
      <Wrapper title={'Reprovar Propriedade'}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Dropdown
            value={selectedPatrol}
            onChange={(e) => setSelectedPatrol(e.value)}
            options={patrols}
            optionLabel="name"
            placeholder="Selecione o Batalhão"
            style={{ width: '100%' }}
          />
          <TextField
            label="Motivo da Reprovação"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
          />
          <Button
            label="Confirmar Reprovação"
            severity="danger"
            onClick={async () => {
              const confirm = window.confirm("Você tem certeza que deseja reprovar esta propriedade?");
              if (confirm) {
                await PATCH(`/properties/${selectedProduct.userUuid}/${selectedProduct.uuid}/reject`, {
                  patrolUuid: selectedPatrol.uuid,
                  rejectionReason: rejectionReason,
                });
                handleProperties();
                close()
              }
            }}
          />
        </div>
      </Wrapper>
    </>
  )
}
