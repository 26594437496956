import './App.css';
import "primereact/resources/themes/saga-green/theme.css";
import Navigator from './navigation/navigation';
import { AuthProvider } from './context/contextGlobal/authContext';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import ModalCore from './components/Modal/Core';

function App() {
  return (
    <AuthProvider>
      <Navigator />
      <ToastContainer />
      <ModalCore />
    </AuthProvider>
  );
}

export default App;
