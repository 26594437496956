import { Typography } from "@mui/material";
import styled from "styled-components";



export const BodyPage = styled.div`
    width: 100%;
    background-color: white;


`

export const CardLogin = styled.div`
    height: 300px;
    width: 500px;
    background-color: white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const HeaderPage = styled.div`
    padding: 20px;
    width: 99%;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    border-left: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;

    &:before {
        margin: 0px 5px;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 2px;
        background-color: black;
        z-index: 1;
        width: 42%;
        transform: translateY(-50%);
    }

    &:after {
        margin: 0px 5px;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 2px;
        background-color: black;
        z-index: 1;
        width: 42%;
        transform: translateY(-50%);
    }
`

export const TitleWithBorder = styled(Typography)`
    position: relative;
    font-weight: 600;
    text-align: center;
    font-size: 2rem;
`

export const CardWhiteGlobal = styled.div`
    background-color: white;
    border-radius: 10px;
    margin: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


`

export const CardGray = styled.div`
    height: 80%;
    width: 300px;
    background-color: #eae6df;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


`

export const TextTittle = styled.text`
    margin-bottom: 20px;
    font-weight: 700;




`

export const IconStyled = styled.div`
  position: absolute;
  height: 50px;
  width: 50px;
  background-color: red;
  top: 40px;
  right: 15%;
  cursor: pointer;

`;