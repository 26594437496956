import React, { useContext } from 'react';
import { FormText, Title } from '../../../ui/styled';
import { CompanyButtonContainer } from './styled';
import Wrapper from '../Wrapper';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import { Button } from 'primereact/button';
import { WrapperButtonContainer } from './styled';

export default function ModalChooseAction() {

  const { modal, setModal } = useContext(AuthContext)
  console.log("🚀 ~ ModalChooseAction ~ modal: chooseAction:", modal)

  const close = () => {
    setModal(null)
  }

  const handleSave = () => {
    // do something
    close()
  }

  const handleApprove = () => {
    setModal({
      type: 'approve',
    })
  }

  const handleReprove = () => {
    setModal({
      type: 'reprove',
    })
  }

  return (
    <>
      <Wrapper title={'Escolha uma ação'}>
        <WrapperButtonContainer>
          <Button
            label="Aprovar"
            severity="success"
            onClick={handleApprove}
          />
          <Button
            label="Reprovar"
            severity="danger"
            onClick={handleReprove}
          />
        </WrapperButtonContainer>
      </Wrapper>
    </>
  )
}
