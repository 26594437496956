import React, { useContext, useRef, useState } from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import logo from "../../../assets/img/logo.png";
import { BodyPage, CardLogin } from "../../styledGlobal";
import { useNavigate } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { AuthContext } from "../../../context/contextGlobal/authContext";

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const toast = useRef(null);
    const navigate = useNavigate();

    const { login, loading } = useContext(AuthContext);

    const handleLogin = async () => {
        try {
            await login(email, password);
            navigate('/home');
        } catch (err) {
            console.log("🚀 ~ handleLogin ~ err:", err)
            const translatedMessage = translateErrorMessage(err.message);
            showError(translatedMessage);
        }
    };

    const translateErrorMessage = (message) => {
        if (message.includes('HttpException: User is not approved for web access')) {
            return 'Usuário não autorizado';
        } else if (message.includes('Invalid credentialsHttpException: Invalid credentiais: HttpException: Não foi encontrado nenhum registro com o UUID especificado')) {
            return 'Credenciais inválidas';
        } else if (message.includes('HttpException: Login failed')) {
            return 'Falha no login';
        } else {
            return 'Erro ao fazer login. Por favor, tente novamente.';
        }
    };

    const showError = (message) => {
        toast.current.show({ severity: 'error', summary: 'Erro', detail: message, life: 3000 });
    };



    return (
        <BodyPage style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100vh" }}>
            <img style={{ height: 100, width: 300, marginTop: "10%" }} src={logo} alt="Logo" />
            <Toast ref={toast} />
            <CardLogin style={{ marginTop: 40 }}>
                <InputText
                    style={{ marginBottom: 10 }}
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <InputText
                    type="password"
                    style={{ marginBottom: 30 }}
                    placeholder="Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    label="Login"
                    icon="pi pi-check"
                    loading={loading}
                    onClick={handleLogin}
                />
            </CardLogin>
        </BodyPage>
    );
};

export default LoginPage;
