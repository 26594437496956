import React from 'react';
import { Outlet } from 'react-router-dom';
import { BodyPage } from '../pages/styledGlobal';
import MenuPage from './menuPage';
import logo from "../assets/img/logo.png";


const Layout = () => {
    return (
        <BodyPage style={{ display: 'flex', flexDirection: "column", backgroundColor: "#f2f3f4" }}>
            <div style={{ width: '100%', backgroundColor: "white" }}>
                <img style={{ height: 50, width: 150, backgroundColor: "white", margin: 20 }} src={logo} alt="Logo" />
                <MenuPage />
            </div>
            <Outlet />
        </BodyPage>
    );
}

export default Layout;
