import styled from "styled-components";

export const CompanyDataContainer = styled.div.attrs({
})`

`;

export const CloseContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const Title = styled.div.attrs({
})`
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    white-space: nowrap;
    text-align: left;
    text-transform: uppercase;
    color: ${props => props.theme.palette.colors.lightblack};
    `
    
export const WrapperTitleAndCloseIcon = styled.div.attrs({
})`
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* padding: 24px; */
    padding-bottom: 24px;
`;