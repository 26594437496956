import React, { useState, useEffect, useContext } from "react";
import { Tabs, Tab, Box } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/contextGlobal/authContext";
import BasicDrawer from "../Dashboard/BasicDrawer";

const MenuPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [value, setValue] = useState(0);

    const { user, filteredData } = useContext(AuthContext);
    console.log("🚀 ~ MenuPage ~ filteredData:", filteredData)

    const userRole = user?.role?.name;
    console.log("🚀 ~ MenuPage ~ userRole:", userRole)

    useEffect(() => {
        switch (location.pathname) {
            case '/home':
                setValue(0);
                break;
            case '/activeUsersList':
                setValue(1);
                break;
            default:
                setValue(0);
                break;
        }
    }, [location.pathname]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                navigate('/home');
                break;
            case 1:
                navigate('/activeUsersList');
                break;
            default:
                break;
        }
    };

    const drawerItems = [
        { text: 'Usuários ativos', onClick: () => navigate('/activeUsersList') },
        { text: 'Usuários inativos', onClick: () => navigate('/inactiveUsersList') },
    ];

    const tabsComponent = (
        <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
        >
            <Tab label="Home" />
            <Tab label="Total de Usuários" />
        </Tabs>
    );

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper', marginLeft: 2, marginRight: 2, }}>
            <BasicDrawer drawerItems={drawerItems} tabsComponent={tabsComponent} />
        </Box>
    );
}

export default MenuPage;
