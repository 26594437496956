import React, { createContext, useState, useEffect } from "react";
import api from "../../service/service";
import { ReadObject, SaveObject } from "../../service/storage";
import { DoLogin } from "../../service/auth";
import { Read } from "../../service/property";
import { Read as ReadUsers } from "../../service/users";
import { Read as ReadPatrols } from "../../service/patrols";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [modal, setModal] = useState(null)
  const [loading, setLoading] = useState(true);
  const [propriets, setPropriets] = useState(0);
  const [dados, setDados] = useState([]);
  console.log("🚀 ~ AuthProvider ~ dados:", dados)
  const [users, setUsers] = useState([]);
  const [userInfos, setUserInfos] = useState(null);
  const [total, setTotal] = useState(0);
  const [totalUsersActive, setTotalUsersActive] = useState(0);
  const [totalUsersInactive, setTotalUsersInactive] = useState(0);
  const [totalPropriedadesReprovadas, setTotalPropriedadesReprovadas] = useState(0);
  const [totalPropriedadesVisitadas, setTotalPropriedadesVisitadas] = useState(0);
  const [totalPropriedadesAguardando, setTotalPropriedadesAguardando] = useState(0);
  const [totalPropriedadesAprovadas, setTotalPropriedadesAprovadas] = useState(0);
  const [totalPropriedadesInativas, setTotalPropriedadesInativas] = useState(0);
  const [patrols, setPatrols] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [userProperties, setUserProperties] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState(dados[0]);
  const [mapCenter, setMapCenter] = useState({ lat: -15.793889, lng: -47.882778 });

  const updateFilteredData = (filters) => {
    let filtered = [...dados];

    if (filters.selectedUser) {
      filtered = filtered.filter(item => item.userUuid === filters.selectedUser);
    }

    if (filters.selectedPropriet) {
      filtered = filtered.filter(item => item.uuid === filters.selectedPropriet);
    }

    if (filters.status) {
      filtered = filtered.filter(item => item.status === filters.status);
    }

    if (filters.statusVisit) {
      filtered = filtered.filter(item => item.status_visit === filters.statusVisit);
    }

    if (filters.area) {
      filtered = filtered.filter(item => item.area && item.area.includes(filters.area));
    }

    if (filters.municipality) {
      filtered = filtered.filter(item => item.municipality && item.municipality.includes(filters.municipality));
    }

    if (filters.line) {
      filtered = filtered.filter(item => item.line && item.line.includes(filters.line));
    }

    if (filters.patrolUuid) {
      filtered = filtered.filter(item => item.patrol?.uuid === filters.patrolUuid); // Filtro de batalhão
    }

    setFilteredData(filtered);
  };


  useEffect(() => {
    const recoveredUser = ReadObject("user");
    const token = localStorage.getItem("@token");
    const properties = ReadObject("propriets");

    if (recoveredUser && token) {
      setUser(recoveredUser);
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
    handleUsers();

    setLoading(false);
  }, []);

  const login = async (email, password) => {
    try {
      const response = await DoLogin({ email, password });

      if (response?.access_token) {
        SaveObject("user", response.user);
        localStorage.setItem("@token", response.access_token);

        setUser(response.user);
        api.defaults.headers.Authorization = `Bearer ${response.access_token}`;
      } else {
        throw new Error("Token is missing from response");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message || "Login failed");
      }
    }
  };

  const handleProperties = async () => {
    try {
      const response = await Read();

      const totalUsersActive = response?.data?.result.filter(user => user.status === 'ativo').length;
      const totalUsersInactive = response?.data?.result.filter(user => user.status === 'inativo').length;
      const totalPropriedadesReprovadas = response?.data?.result.filter(property => property.status === 'reprovada').length;
      const totalPropriedadesVisitadas = response?.data?.result.filter(property => property.status_visit === 'ativo').length;

      const totalPropriedadesAguardando = response?.data?.result.filter(property =>
        property.status === 'Aguardando' ||
        property.status === 'inativo' ||
        property.status_visit === 'Aguardando' ||
        property.status_visit === 'inativo'
      ).length;

      const totalPropriedadesAprovadas = response?.data?.result.filter(property => property.status === 'ativo').length;

      const totalPropriedadesInativas = response?.data?.result.filter(property => property.status === 'inativo').length;

      setTotalUsersActive(totalUsersActive);
      setTotalUsersInactive(totalUsersInactive);
      setPropriets(response?.data?.totalResults);  
      setDados(response.data.result);  
      setFilteredData(response.data.result);
      setTotalPropriedadesReprovadas(totalPropriedadesReprovadas); 
      setTotalPropriedadesVisitadas(totalPropriedadesVisitadas);

      setTotalPropriedadesAguardando(totalPropriedadesAguardando);
      setTotalPropriedadesAprovadas(totalPropriedadesAprovadas); 
      setTotalPropriedadesInativas(totalPropriedadesInativas);
    } catch (err) {
      console.log("🚀 ~ handleProperties ~ err:", err);
    }
  };

  const handleUsers = async () => {
    try {
      const response = await ReadUsers();
      setTotal(response?.data?.totalResults)
      setUsers(response?.data?.result)
    } catch (err) {
      console.log("🚀 ~ handleUsers ~ err:", err)
      console.error("Error fetching users: ", err);
    }
  };

  const init = async () => {
    try {
      const response = await ReadPatrols();

      const patrolsData = response?.data?.map((patrol) => ({
        name: patrol.name,
        code: patrol.code,
        uuid: patrol.uuid,
      }));

      patrolsData.sort((a, b) => {
        const numA = parseInt(a.name.split('º')[0]);
        const numB = parseInt(b.name.split('º')[0]);
        return numA - numB;
      });

      setPatrols(patrolsData || []);
    } catch (error) {
      console.log("🚀 ~ init ~ error:", error);
    }
  };

  useEffect(() => { init() }, []);

  return (
    <AuthContext.Provider value={{
      authenticated: !!user,
      user,
      loading,
      login,
      propriets,
      handleProperties,
      users,
      setUsers,
      total,
      dados,
      userInfos,
      setUserInfos,
      filteredData,
      setFilteredData,
      userProperties,
      setUserProperties,
      selectedProduct,
      setSelectedProduct,
      mapCenter,
      setMapCenter,
      updateFilteredData,
      totalUsersActive,
      totalUsersInactive,
      totalPropriedadesReprovadas,
      totalPropriedadesVisitadas,
      totalPropriedadesAguardando,
      totalPropriedadesAprovadas,
      totalPropriedadesInativas,
      modal,
      setModal,
      patrols,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

