import React, { useContext, useEffect, useState } from 'react';
import Wrapper from '../Wrapper';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { IconStyled } from '../../../pages/styledGlobal';
import gifLoc from "../../../assets/img/terra.gif";
import { GET } from '../../../service/service';
import semImagem from "../../../assets/img/semfoto.jpg";

export default function ModalViewDetails() {

  const { modal, setModal, setMapCenter, setSelectedProduct } = useContext(AuthContext)
  console.log("🚀 ~ ModalViewDetails ~ modal:", modal)
  const [infosRowDataUser, setInfosRowDataUser] = useState(null);
  const [infosRowData, setInfosRowData] = useState(null);
  const [dropdowns, setDropDowns] = useState(null);
  const [equipements, setEquipment] = useState([]);
  const [veicles, setVeicles] = useState([]);
  const [quimis, setQuimis] = useState([]);
  const [agros, setAgros] = useState([]);
  const [photo, setPhoto] = useState('');

  const close = () => {
    setModal(null)
  }

  const handleSave = () => {
    // do something
    close()
  }

  const actionModalSelect = async () => {
    try {
      const userResponse = await GET(`/users/${modal?.data?.userUuid}`);
      const equipmentResponse = await GET(`/equipments/property/${modal?.data?.uuid}?take=30&skip=0`);
      const vehicleResponse = await GET(`/vehicles/property/${modal?.data?.uuid}?take=30&skip=0`);
      const bioChemicalResponse = await GET(`/biochemical-inputs/property/${modal?.data?.uuid}?take=30&skip=0`);
      const agroResponse = await GET(`/agricultural-productions/property/${modal?.data?.uuid}?take=30&skip=0`);

      setInfosRowDataUser(userResponse.data);
      setInfosRowData(modal?.data);
      setMapCenter({ lat: modal?.data?.lat, lng: modal?.data?.long });
      setEquipment(equipmentResponse.data?.result);
      setVeicles(vehicleResponse.data?.result);
      setQuimis(bioChemicalResponse.data?.result);
      setAgros(agroResponse.data?.result);
    } catch (err) {
      console.log(err);
    }
  };

  const handleModalSelectProduct = () => {
    setSelectedProduct(infosRowData)

    window.scrollTo(0, 0);
  };

  const optionsDropdowns = [
    { name: 'Equipamentos', code: 'EP' },
    { name: 'Veiculos', code: 'VC' },
    { name: 'Bioquímicos', code: 'BQ' },
    { name: 'Produção Agricola', code: 'PA' },
  ];

  const equipment = equipements.map((itens) => ({
    name: `${itens?.name} - ${itens?.category}`,
    code: itens?.uuid,
    photo: itens?.galleries[0]?.avatarUrl
  }));

  const veicle = veicles.map((itens) => ({
    name: `${itens?.model} - ${itens?.type}`,
    code: itens?.uuid,
    photo: itens?.galleries[0]?.avatarUrl
  }));

  const quimi = quimis.map((itens) => ({
    name: `${itens?.name} - ${itens?.type}`,
    code: itens?.uuid,
    photo: itens?.galleries[0]?.avatarUrl
  }));
  const agrio = agros.map((itens) => ({
    name: `${itens?.name} - ${itens?.type}`,
    code: itens?.uuid,
    photo: itens?.galleries[0]?.avatarUrl
  }));


  const selectDropDowns = () => {
    if (dropdowns?.code === "EP") {
      return (
        <Dropdown style={{ width: 200 }} value={photo} onChange={(e) => setPhoto(e.value)} options={equipment} optionLabel="name"
          placeholder="Equipamentos" className="w-full md:w-14rem" />
      );
    } else if (dropdowns?.code === "VC") {
      return (
        <Dropdown style={{ width: 200, marginTop: 20 }} value={photo} onChange={(e) => setPhoto(e.value)} options={veicle} optionLabel="name"
          placeholder="Veiculos" className="w-full md:w-14rem" />
      );
    } else if (dropdowns?.code === "BQ") {
      return (
        <Dropdown style={{ width: 200, marginTop: 20 }} value={photo} onChange={(e) => setPhoto(e.value)} options={quimi} optionLabel="name"
          placeholder="Insumo bioquímicos" className="w-full md:w-14rem" />
      );
    } else {
      return (
        <Dropdown style={{ width: 200, marginTop: 20 }} value={photo} onChange={(e) => setPhoto(e.value)} options={agrio} optionLabel="name"
          placeholder="Produção agrícola" className="w-full md:w-14rem" />
      );
    }
  };

  useEffect(() => {
    actionModalSelect()
  }, []);

  return (
    <>
      <Wrapper width={'100%'}>
        <div style={{ display: "flex", flexDirection: "row", width: '100%' }}>
          <div style={{ width: '50%', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

                <text style={{ fontWeight: 500 }}>Nome do Proprietario</text>
                <text style={{ marginBottom: 20 }}>{infosRowDataUser?.fullName}</text>
                <text style={{ fontWeight: 500 }}>E-mail</text>
                <text style={{ marginBottom: 20 }}>{infosRowDataUser?.email}</text>
                <text style={{ fontWeight: 500 }}>Telefone</text>
                <text style={{ marginBottom: 20 }}>{infosRowDataUser?.phoneNumbers[0]?.phoneNumber}</text>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

                    <Dropdown style={{ width: 200, marginBottom: 20 }} value={dropdowns} onChange={(e) => setDropDowns(e.value)} options={optionsDropdowns} optionLabel="name"
                      placeholder="Categoria" className="w-full md:w-14rem" />

                    {selectDropDowns()}

                  </div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginLeft: 30 }}>

                    <Image src={photo ? photo?.photo : semImagem} alt="Image" width="250px" />

                  </div>




                </div>


              </div>


            </div>
          </div>
          <div style={{ width: '50%', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <IconStyled onClick={() => handleModalSelectProduct()}>
              <img src={gifLoc} style={{ height: "100%", width: "100%" }}></img>
            </IconStyled>
            <Image src={infosRowData?.galleries[0]?.avatarUrl ? infosRowData?.galleries[0]?.avatarUrl : semImagem} alt="Image" width="250px" />

            <text style={{ fontWeight: 500 }}>Nome da Propriedade</text>
            <text style={{ marginBottom: 20 }}>{infosRowData?.name}</text>
            <text style={{ fontWeight: 500 }}>Nome do Município</text>
            <text style={{ marginBottom: 20 }}>{infosRowData?.municipality === null ? 'Nenhum município cadastrado' : infosRowData?.municipality}</text>
            <text style={{ fontWeight: 500 }}>Linha</text>
            <text style={{ marginBottom: 20 }}>{infosRowData?.line === null ? 'Nenhuma linha cadastrado' : infosRowData?.line}</text>
            <text style={{ fontWeight: 500 }}>Estado</text>
            <text style={{ marginBottom: 20 }}>{infosRowData?.state === null ? 'Nenhum estado cadastrado' : infosRowData?.state}</text>
          </div>
        </div>
      </Wrapper>
    </>
  )
}
