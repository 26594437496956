import { POST } from "./service";
import { SaveObject } from "./storage";

export const DoLogin = async params => { 
    const response = await POST(`/simple-login`, params); 
    if (response?.access_token) {
        await SaveObject('authentication', response); // Salva o token de autenticação
    }
    return response;
}
