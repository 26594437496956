import React, { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { Main, DrawerHeader, drawerStyles } from './styled';
import { AuthContext } from '../../../context/contextGlobal/authContext';

export default function BasicDrawer({ children, tabsComponent }) {
  const theme = useTheme();
  const {
    dados,
    updateFilteredData,
    users,
    userProperties,
    setUserProperties,
    filteredData,
    setSelectedProduct,
    setMapCenter
  } = useContext(AuthContext);

  const [open, setOpen] = useState(false);

  const [status, setStatus] = useState('');
  const [statusVisit, setStatusVisit] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [deletedAt, setDeletedAt] = useState('');
  const [state, setState] = useState('');
  const [municipality, setMunicipality] = useState('');
  const [line, setLine] = useState('');
  const [area, setArea] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPropriet, setSelectedPropriet] = useState(null);
  const [selectedPatrol, setSelectedPatrol] = useState(null);

  const usersOpt = users.map((itens) => ({
    label: itens?.fullName,
    code: itens?.uuid
  }));

  const propriOpt = userProperties.map((itens) => ({
    label: itens?.name,
    code: itens?.uuid
  }));

  const patrolOptions = dados
    .map((item) => ({
      label: item?.patrol?.name,
      code: item?.patrol?.uuid,
    }))
    .filter((option) => option.label && option.code);

  useEffect(() => {
    if (selectedUser && dados.length > 0) {
      const properties = dados.filter(item => item.userUuid === selectedUser.code);
      setUserProperties(properties);
      setSelectedPropriet(null);
    } else {
      setUserProperties([]);
    }
  }, [selectedUser, dados, setUserProperties]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleApplyFilters = () => {
    const filters = {
      selectedUser: selectedUser?.code || '',
      selectedPropriet: selectedPropriet?.code || '',
      status,
      statusVisit,
      updatedAt,
      deletedAt,
      state,
      municipality,
      line,
      area,
      patrolUuid: selectedPatrol?.code || '',
    };

    updateFilteredData(filters);

    if (filteredData.length > 0) {
      const firstProduct = filteredData[0];
      setSelectedProduct(firstProduct);
      setMapCenter({ lat: firstProduct.lat, lng: firstProduct.long });
    }
  };


  const handleClearFilters = () => {
    setSelectedUser(null);
    setSelectedPropriet(null);
    setStatus('');
    setStatusVisit('');
    setUpdatedAt('');
    setDeletedAt('');
    setState('');
    setMunicipality('');
    setLine('');
    setArea('');
    updateFilteredData({});
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        {tabsComponent}
      </Box>
      <Drawer
        sx={drawerStyles}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6" noWrap component="div" sx={{ paddingLeft: 1 }}>
            Filtros
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <Box sx={{ padding: 2 }}>
            <Autocomplete
              value={selectedUser}
              onChange={(e, newValue) => setSelectedUser(newValue)}
              options={usersOpt}
              renderInput={(params) => <TextField {...params} label="Proprietário" />}
              sx={{ marginBottom: 2 }}
            />

            <Autocomplete
              value={selectedPropriet}
              onChange={(e, newValue) => setSelectedPropriet(newValue)}
              options={propriOpt}
              renderInput={(params) => <TextField {...params} label="Propriedade" />}
              sx={{ marginBottom: 2 }}
              disabled={userProperties.length === 0}
            />

            <Autocomplete
              value={selectedPatrol}
              onChange={(e, newValue) => setSelectedPatrol(newValue)}
              options={patrolOptions}
              renderInput={(params) => <TextField {...params} label="Batalhão" />}
              sx={{ marginBottom: 2 }}
            />

            <TextField
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status"
              fullWidth
              select
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="">Status</MenuItem>
              <MenuItem value="ativo">Ativo</MenuItem>
              <MenuItem value="inativo">Inativo</MenuItem>
              <MenuItem value="Aguardando">Aguardando</MenuItem>
            </TextField>

            <TextField
              value={statusVisit}
              onChange={(e) => setStatusVisit(e.target.value)}
              label="Status de Visita"
              fullWidth
              select
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="">Status de Visita</MenuItem>
              <MenuItem value="ativo">Visitado</MenuItem>
              <MenuItem value="inativo">Não visitado</MenuItem>
            </TextField>

            <TextField
              value={area}
              onChange={(e) => setArea(e.target.value)}
              label="Área"
              fullWidth
              sx={{ marginBottom: 2 }}
            />

            <TextField
              value={municipality}
              onChange={(e) => setMunicipality(e.target.value)}
              label="Endereço"
              fullWidth
              sx={{ marginBottom: 2 }}
            />

            <TextField
              value={line}
              onChange={(e) => setLine(e.target.value)}
              label="Linha"
              fullWidth
              sx={{ marginBottom: 2 }}
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleApplyFilters}
              sx={{ mt: 2 }}
            >
              Aplicar Filtros
            </Button>

            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={handleClearFilters}
              sx={{ mt: 2 }}
            >
              Limpar Filtros
            </Button>
          </Box>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}
