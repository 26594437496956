import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';

export default function BasicCard({ title, value, duration = 2500 }) {
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    let startValue = 0;
    const increment = value / (duration / 10);

    const counter = setInterval(() => {
      startValue += increment;
      if (startValue >= value) {
        startValue = value;
        clearInterval(counter);
      }
      setDisplayValue(Math.round(startValue));
    }, 10);

    return () => clearInterval(counter);
  }, [value, duration]);

  return (
    <Card sx={{ minWidth: 150, textAlign: 'center', margin: '0 15px' }}>
      <CardContent>
        <Typography variant="h6" component="div" sx={{ fontSize: 15 }}>
          {title}
        </Typography>
        <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', marginTop: 1 }}>
          {displayValue.toLocaleString()} {/* Adiciona separador de milhar */}
        </Typography>
      </CardContent>
    </Card>
  );
};
