import axios from 'axios';
import { ReadObject } from './storage';

const ENDPOINTS = {
  'localhost': 'http://localhost:8080',
  'central.rrapp.com.br': 'https://api.rrapp.com.br',
};

const envEndpoint = () => {
  return (
    Object.keys(ENDPOINTS)
      .filter(fit => `${window.location.origin}`.indexOf(fit) !== -1)
      .map(key => ENDPOINTS[key])[0] || ENDPOINTS['rrapp.com.br']
  );
};

export const API_ENDPOINT = envEndpoint();

const api = axios.create({
  baseURL: API_ENDPOINT,
});

api.interceptors.request.use(async (config) => {
  const authentication = await ReadObject('authentication');
  if (authentication?.jwt) {
    config.headers.Authorization = `Bearer ${authentication.jwt}`;
  }
  return config;
});

export const GET = async (path) => {
  return await api.get(path);
};

export const POST = async (path, body, isBlob = false) => {
  const headers = {
    'Content-Type': isBlob ? 'multipart/form-data' : 'application/json',
  };
  const response = await api.post(path, body, { headers });
  return response.data;
};

export const PUT = async (path, body) => {
  return await api.put(path, body);
};

export const PATCH = async (path, body) => {
  return await api.patch(path, body);
};

export const DELETE = async (path) => {
  return await api.delete(path);
};

export const PostImage = async (fileToUpload, forward) => {
  const formData = new FormData();
  let headers = { 'Content-Type': 'multipart/form-data' };
  if (forward) {
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('type', forward?.type);
    formData.append('payload', JSON.stringify(forward?.payload));

    const response = await api.post('/holerite-generate', formData, { headers });
    return response.data;
  } else {
    formData.append('files', fileToUpload, fileToUpload.name);
    const response = await api.post('/upload', formData, { headers });
    return response.data;
  }
};

export const parseStrapiImage = (url) => {
  return !url ? '' : url.indexOf('://') !== -1 ? url : `${API_ENDPOINT}${url}`;
};

export default api;
