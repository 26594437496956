import React, { useContext, useEffect, useState } from 'react';
import { FormText, Title } from '../../../ui/styled';
import { CompanyButtonContainer } from './styled';
import Wrapper from '../Wrapper';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import {  TextField } from '@mui/material';
import { Dropdown } from 'primereact/dropdown';
import { PATCH } from '../../../service/service';
import { Read } from '../../../service/patrols';
import { Button } from 'primereact/button';

export default function ModalApprove() {

  const { setModal, selectedProduct, handleProperties, patrols } = useContext(AuthContext)
  const [propertyNumber, setPropertyNumber] = useState('');
  const [selectedPatrol, setSelectedPatrol] = useState(null);

  const close = () => {
    setModal(null)
  }

  const handleConfirm = () => {
    
  }

  return (
    <>
      <Wrapper title={'Aprovar Propriedade'}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <TextField
            label="Número da Propriedade"
            variant="outlined"
            fullWidth
            value={propertyNumber}
            onChange={(e) => setPropertyNumber(e.target.value)}
          />
          <Dropdown
            value={selectedPatrol}
            onChange={(e) => setSelectedPatrol(e.value)}
            options={patrols}
            optionLabel="name"
            placeholder="Selecione o Batalhão"
            style={{ width: '100%' }}
          />
          <Button
            label="Confirmar Aprovação"
            severity="success"
            onClick={async () => {
              const confirm = window.confirm("Você tem certeza que deseja aprovar esta propriedade?");
              if (confirm) {
                await PATCH(`/properties/${selectedProduct.userUuid}/${selectedProduct.uuid}/activate`, {
                  patrolUuid: selectedPatrol.uuid,
                  propertyNumber: propertyNumber,
                });
                handleProperties()
                close()
              }
            }}
          />
        </div>
      </Wrapper>
    </>
  )
}
