import { GET, PATCH } from "./service";

export const Read = async () => {
    const response = await GET(`/users?take=500&skip=0`); 
    return response;
}

export const Update = async (id, data) => {
    const response = await PATCH(`/users/${id}`, data, true);
    return response;
}