/* eslint-disable jsx-a11y/alt-text */
import { TextField } from "@mui/material";
import { Button } from "primereact/button";
import { Chart } from 'primereact/chart';
import { Dialog } from 'primereact/dialog';
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../../components/Dashboard/BasicCard";
import BasicTable from "../../../components/Dashboard/BasicTable";
import { AuthContext } from "../../../context/contextGlobal/authContext";
import { PATCH } from "../../../service/service";
import { CardWhiteGlobal, HeaderPage, TitleWithBorder } from "../../styledGlobal";
import { Wrapper } from "./styled";
import MapContainer from "../../../components/googleMaps";


const HomePage = () => {
    const { propriets, handleProperties, users, patrols, filteredData, selectedProduct, setSelectedProduct, mapCenter, setMapCenter, totalPropriedadesReprovadas, totalPropriedadesVisitadas, totalPropriedadesAguardando, totalPropriedadesAprovadas, totalPropriedadesInativas, setModal } = useContext(AuthContext);
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [visibleApprove, setVisibleApprove] = useState(false);
    const [visibleInactivate, setVisibleInactivate] = useState(false);
    const [propertyNumberInput, setPropertyNumberInput] = useState('');

    const mapRef = useRef(null);
    const navigation = useNavigate();

    const [chartDataMun, setChartDataMun] = useState({});
    const [chartOptionsMun, setChartOptionsMun] = useState({});

    useEffect(() => {
        const token = localStorage.getItem('@token');
        if (!token) {
            navigation('/');
        }

        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ['Nova ocorrência', 'Finalizado', 'Em progresso'],
            datasets: [
                {
                    data: [300, 50, 100],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-500'),
                        documentStyle.getPropertyValue('--yellow-500'),
                        documentStyle.getPropertyValue('--green-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'),
                        documentStyle.getPropertyValue('--yellow-400'),
                        documentStyle.getPropertyValue('--green-400')
                    ]
                }
            ]
        };
        const options = {
            cutout: '60%'
        };

        setChartData(data);
        setChartOptions(options);
    }, [navigation]);

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ['Alvorada do Oeste', 'Alvorada do Leste', 'Porto velho'],
            datasets: [
                {
                    data: [10, 5, 22],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-500'),
                        documentStyle.getPropertyValue('--yellow-500'),
                        documentStyle.getPropertyValue('--green-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'),
                        documentStyle.getPropertyValue('--yellow-400'),
                        documentStyle.getPropertyValue('--green-400')
                    ]
                }
            ]
        };
        const options = {
            cutout: '60%'
        };

        setChartDataMun(data);
        setChartOptionsMun(options);
    }, []);

    useEffect(() => {
        if (isMapLoaded && mapRef.current) {
            mapRef.current.setCenter(new window.google.maps.LatLng(mapCenter.lat, mapCenter.lng));
        }
    }, [mapCenter, isMapLoaded]);

    const actionActive = async (rowData) => {
        if (rowData?.status === 'ativo') {
            setSelectedProduct(rowData);
            setVisibleInactivate(true);
        } else if (rowData?.status === 'inativo') {
            setModal({
                type: 'chooseAction',
                data: rowData
            });
        } else if (rowData?.status === 'Aguardando') {
            setModal({
                type: 'chooseAction',
                data: rowData
            });
        }
    };

    const actionActiveVisit = async (userUuid, uuid, status_visit) => {
        if (status_visit === 'visitado') {
            // Desativa diretamente se já foi visitado
            await PATCH(`/properties/${userUuid}/${uuid}/deactivate-visit`, {});
            handleProperties();
        } else {
            // Ativa a visita se não estiver visitado
            await PATCH(`/properties/${userUuid}/${uuid}/activate-visit`, {});
            handleProperties();
        }
    };

    const handleInactivateProperty = async () => {
        if (propertyNumberInput !== selectedProduct.propertyNumber) {
            alert('O número da propriedade não coincide com o número registrado.');
            return;
        }

        const confirm = window.confirm("Tem certeza de que deseja inativar esta propriedade? Esta ação não é reversível.");
        if (confirm) {
            try {
                // Atualiza o status da propriedade para "inativo"
                await PATCH(`/properties/${selectedProduct.userUuid}/${selectedProduct.uuid}`, {
                    status: 'inativo',  // Define o status como inativo
                });
                alert('Propriedade inativada com sucesso.');
                handleProperties();  // Atualiza a lista de propriedades
                setVisibleInactivate(false);  // Fecha o modal
            } catch (err) {
                console.error('Erro ao inativar a propriedade:', err);
                alert('Ocorreu um erro ao inativar a propriedade.');
            }
        }
    };

    const actionModalSelect = async (rowData) => {
        setModal({
            type: 'viewDetails',
            data: rowData
        });
    };

    const showPropriets = (rowData) => {
        const user = users.find(user => user?.uuid === rowData?.userUuid);
        return <span>{user?.fullName}</span>
    };

    useEffect(() => {
        handleProperties()
    }, []);

    const columns = [
        { field: 'name', headerName: 'Nome da Propriedade', sortable: true },
        { field: 'proprietario', headerName: 'Nome do Proprietario', renderCell: showPropriets, sortable: true },
        { field: 'Número da Propriedade', headerName: 'Número da Propriedade', renderCell: (rowData) => rowData?.propertyNumber || 'Não cadastrado', sortable: true },
        { field: 'area', headerName: 'Área', sortable: true },
        { field: 'municipality', headerName: 'Endereço', sortable: true },
        { field: 'line', headerName: 'Linha', sortable: true },
        { field: 'patrol', headerName: 'Batalhão', renderCell: (rowData) => rowData?.patrol?.name || 'Sem Batalhão', sortable: true },
        {
            field: 'status', headerName: 'Status', renderCell: (rowData) => (
                <Button
                    severity={rowData?.status === 'inativo' || rowData?.status === '' ? 'secondary' :
                        rowData?.status === 'reprovada' ? 'danger' :
                            rowData?.status === 'Aguardando' ? 'warning' :
                                'success'}
                    onClick={() => actionActive(rowData)}
                >
                    {rowData?.status === 'inativo' || rowData?.status === '' ? 'Inativo' :
                        rowData?.status === 'reprovada' ? 'Reprovada' :
                            rowData?.status === 'Aguardando' ? 'Aguardando' : 'Ativo'}
                </Button>
            ),
            sortable: true
        },
        {
            field: 'visita', headerName: 'Visita', renderCell: (rowData) => (
                <Button
                    severity={rowData?.status_visit === 'inativo' || rowData?.status_visit === '' ? 'secondary' : 'success'}
                    onClick={() => actionActiveVisit(rowData?.userUuid, rowData?.uuid, rowData?.status_visit)}
                >
                    {rowData?.status_visit === 'inativo' || rowData?.status_visit === '' ? 'Não Visitado' : 'Visitado'}
                </Button>
            ),
            sortable: true
        },
        {
            field: 'informacoes',
            headerName: 'Informações',
            renderCell: (rowData) => (
                <Button onClick={() => actionModalSelect(rowData)}>
                    Abrir
                </Button>
            ),
            sortable: false
        },
    ];

    const handleRowClick = (rowData) => {
        setMapCenter({ lat: rowData.lat, lng: rowData.long });
        setSelectedProduct(rowData);
    };


    return (
        <>
            <HeaderPage>
                <TitleWithBorder variant="h4" style={{
                    marginTop: '-40px',
                }}>Propriedades</TitleWithBorder>

                <Wrapper>
                    <BasicCard title="Total" value={propriets} />
                    <BasicCard title="Total Reprovadas" value={totalPropriedadesReprovadas} />
                    <BasicCard title="Total Visitadas" value={totalPropriedadesVisitadas} />

                    <BasicCard title="Total Aguardando" value={totalPropriedadesAguardando} />
                    <BasicCard title="Total Aprovadas" value={totalPropriedadesAprovadas} />
                    <BasicCard title="Total Inativas" value={totalPropriedadesInativas} />
                </Wrapper>
            </HeaderPage>

            <div style={{ display: "flex", flexDirection: 'column' }}>
                <div>
                    <CardWhiteGlobal style={{ padding: 10, width: '98%', height: 600 }} >
                        <MapContainer selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
                    </CardWhiteGlobal>
                </div>
                <div style={{
                    margin: 10,
                }}>
                    <BasicTable
                        columns={columns}
                        data={filteredData}
                        pagination={true}
                        onRowClick={handleRowClick}
                    />
                </div>

                <div style={{
                    margin: 10,
                }}>
                    <BasicTable
                        columns={[
                            { field: 'name', headerName: 'Nome do batalhão' },
                            { field: 'code', headerName: 'Código do batalhão' }
                        ]}
                        data={patrols}
                        pagination={true}
                    />
                </div>
                <div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <CardWhiteGlobal style={{ height: 500, width: 500 }}>
                                <span style={{ fontSize: 20, fontWeight: 700 }}>Ocorrências por Status</span>
                                <Chart style={{ marginTop: 50 }} height="350px" type="doughnut" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
                            </CardWhiteGlobal>
                        </div>
                        <div>
                            <CardWhiteGlobal style={{ height: 500, width: 700 }}>
                                <span style={{ fontSize: 20, fontWeight: 700 }}>Ocorrências por Município</span>
                                <Chart style={{ marginTop: 50 }} height="350px" width="600px" type="bar" data={chartDataMun} options={chartOptionsMun} />
                            </CardWhiteGlobal>
                        </div>
                    </div>
                </div>

                <Dialog header="Inativar Propriedade" visible={visibleInactivate} style={{ width: '50%' }} onHide={() => setVisibleInactivate(false)}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <p>Insira o número da propriedade <strong>{selectedProduct?.propertyNumber}</strong> para inativar. Esta ação não poderá ser revertida.</p>

                        <TextField
                            label="Número da Propriedade"
                            variant="outlined"
                            fullWidth
                            value={propertyNumberInput}
                            onChange={(e) => setPropertyNumberInput(e.target.value)}
                            placeholder="Exemplo: 900"
                        />

                        <Button
                            label="Inativar Propriedade"
                            severity="danger"
                            onClick={handleInactivateProperty}
                        />
                    </div>
                </Dialog>
            </div>
        </>
    )
}

export default HomePage;
