import React, { useContext } from 'react'

import { CloseContainer, CompanyDataContainer, Title, WrapperTitleAndCloseIcon } from './styled'
import { Icon, ModalContainer, ModalContent, Overlay } from '../../../ui/styled'

import { AuthContext } from '../../../context/contextGlobal/authContext'

export default function Wrapper({ children, title, width }) {

  const { setModal } = useContext(AuthContext)

  const close = () => { setModal(null); }

  const handleClose = (e) => {
    const mc = document.getElementById('modal-content');
    // Verifica se o clique foi fora do conteúdo do modal
    if (mc && !mc.contains(e.target)) {
      close();
    }
  };

  return (
    <>
      <Overlay onClick={handleClose} >
        <ModalContainer>
          <ModalContent width={width}>
            <CompanyDataContainer>
              <WrapperTitleAndCloseIcon>
                <Title>{title}</Title>
                <CloseContainer>
                  <Icon icon={'close-big'} pointer={true} onClick={close} />
                </CloseContainer>
              </WrapperTitleAndCloseIcon>
              {children}
            </CompanyDataContainer>
          </ModalContent>
        </ModalContainer>
      </Overlay>
    </>
  )
}
