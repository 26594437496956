import React, { useContext, useEffect, useRef, useState } from 'react';
import { Map, Marker } from 'google-maps-react';
import styled from 'styled-components';
import { AuthContext } from '../context/contextGlobal/authContext';

const MapContainerWrapper = styled.div`
height: 900px;
width: 100%;
position: relative;
`;

const MapContainer = ({selectedProduct, setSelectedProduct}) => {
console.log("🚀 ~ MapContainer ~ selectedProduct:", selectedProduct)

  const { dados } = useContext(AuthContext);

    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const mapRef = useRef(null);
    const [mapCenter, setMapCenter] = useState({ lat: -10.83, lng: -63.34 });
    console.log("🚀 ~ MapContainer ~ setMapCenter:", setMapCenter)
    

    useEffect(() => {
        const scriptId = 'google-maps-script';
        const loadGoogleMapsScript = () => {
            if (!document.getElementById(scriptId)) {
                const script = document.createElement('script');
                script.id = scriptId;
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCTnEMWL5bKQp8MBz6_8162L4iumA-EUaA`;
                script.async = true;
                document.body.appendChild(script);
            }
        };
        loadGoogleMapsScript();
        return () => {
            // Cleanup
            const script = document.getElementById(scriptId);
            if (script) {
                document.body.removeChild(script);
            }
        };

    }, []);


    useEffect(() => {
        // Este efeito atualiza o centro do mapa quando mapCenter muda
        if (isMapLoaded && mapRef.current) {
            mapRef.current.setCenter(new window.google.maps.LatLng(mapCenter.lat, mapCenter.lng));
        }
    }, [mapCenter, isMapLoaded]);


    const handleMapReady = (mapProps, map) => {
        setIsMapLoaded(true);
        mapRef.current = map;
    };




    return (
        <MapContainerWrapper>
            <Map
                google={window.google}
                zoom={selectedProduct === undefined ? 8 : 20}
                center={{ lat: selectedProduct?.lat, lng: selectedProduct?.long}}
                mapTypeId={'satellite'}
                onReady={handleMapReady}
                style={{ width: '100%', height: '100%' }}
            >
                {dados?.map((location, index) => (
                    <Marker
                        key={index}
                        position={{ lat: location.lat, lng: location.long }}
                        name={location.name}
                        onClick={() => setSelectedProduct(location)}
                    />
                ))}
            </Map>
        </MapContainerWrapper>
    );
}

export default MapContainer;
