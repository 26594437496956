import React, { useContext } from 'react'


import { AuthContext } from '../../../context/contextGlobal/authContext'
import { ThemedComponent } from '../../../ui/theme'
import ModalSample from '../Sample'
import ModalApprove from '../Approve'
import ModalViewDetails from '../ViewDetails'
import ModalChooseAction from '../ChooseAction'
import ModalReprovee from '../Reprovee'

export default function ModalCore() {
  const { modal } = useContext(AuthContext)

  return (
    <>
      <ThemedComponent>
        {modal?.type === 'sample' ? <ModalSample /> : null}
        {modal?.type === 'reprove' ? <ModalReprovee /> : null}
        {modal?.type === 'approve' ? <ModalApprove /> : null}
        {modal?.type === 'viewDetails' ? <ModalViewDetails /> : null}
        {modal?.type === 'chooseAction' ? <ModalChooseAction /> : null}
      </ThemedComponent>
    </>
  )
}